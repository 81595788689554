import React from "react";
import "./navfiltro.css";

const NavFiltro = ({ setFiltro, filtro }) => {
    const getIcon = (type) => {
        return filtro === type
            ? `./fotos/nav-icons/${type}-icon-filled.png`
            : `./fotos/nav-icons/${type}-icon.png`;
    };

    return (
        <nav className="nav-container">
            <button onClick={() => setFiltro("individual")} className={filtro=='individual' ? "option check" : "option"}>
                <img src={getIcon("individual")} alt="" />
                Individual
            </button>
            <button onClick={() => setFiltro("casal")} className={filtro=='casal' ? "option check" : "option"}>
                <img src={getIcon("casal")} alt="" />
                Casal
            </button>
            <button onClick={() => setFiltro("familia")} className={filtro=='familia' ? "option check" : "option"}>
                <img src={getIcon("familia")} alt="" />
                Familia
            </button>
        </nav>
    );
};

export default NavFiltro;