import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Corrigido: Remova a pasta 'pages' da importação, pois Home.js e MainScreen.js estão diretamente dentro de src
import Home from './Home';  // Home.js está na pasta 'pages', não é necessário colocar 'pages/' no caminho
import Favorite from './Favorite';
import BaseScreen from './BaseScreen';
import DetailScreen from './DetailScreen';
import LoginScreen from './LoginScreen';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/base" element={<BaseScreen />} />
        <Route path="/detail/:categoria/:id" element={<DetailScreen />} />
        <Route path="/favorites" element={<Favorite />} />
      </Routes>
    </Router>
  );
}

export default App;

