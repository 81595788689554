import React from 'react';
import { FaHome, FaSun, FaUtensils, FaHeart, FaTree } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './footer.css';

const Footer = ({ setCatTitle, categoria }) => {
  const getIconColor = (cat) => {
    return categoria === cat ? "orange" : "#a3a3a3";
  };

  return (
    <div className="footer">
        {/* <Link className="footer-icon">
          <FaHome size={30} color={getIconColor('home')} />
          <span>Home</span>
        </Link> */}
        <button
          onClick={() => setCatTitle('diversao', "Diversão")}
          className="footer-icon"
        >
          <FaSun size={30} color={getIconColor('diversao')} />
          <span>Diversão</span>
        </button>
        <button
          onClick={() => setCatTitle('comer_beber', "Comer&Beber")}
          className="footer-icon"
        >
          <FaUtensils size={30} color={getIconColor('comer_beber')} />
          <span>Comer&Beber</span>
        </button>
        <button
          onClick={() => setCatTitle('relaxar', "Relaxar")}
          className="footer-icon"
        >
          <FaTree size={30} color={getIconColor('relaxar')} />
          <span>Relaxar</span>
        </button>
        <Link to="/favorites" className="footer-icon">
          <FaHeart size={30} color={getIconColor('favoritos')} /> {/* Cor para Favoritos */}
          <span>Favoritos</span>
        </Link>
    </div>
  );
};

export default Footer;