import React, { useState } from 'react';
import { FaLock, FaUser, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import '../styles/loginscreen.css';

const LoginScreen = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };
    

    function showInfo(){
        console.log(email, password);
    }
    return (
        <div className="login-screen">
            <div className="main">
                <div className="logo-container">
                    <img src="/fotos/icone-full.png" alt="" className="logo" />
                </div>
                <div className="form-container">
                    <form action="">
                        <div className="form">
                            <div className="input-box">
                                <FaUser style={{ fontSize: "1.2em", color: "white" }} />
                                <input type="text" placeholder='Email ID or User name' value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="input-box">
                                <FaLock style={{ fontSize: "1.2em", color: "white" }} />
                                <input
                                    type={showPassword ? "text" : "password"}
                                    placeholder='Senha'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <button
                                    type="button"
                                    onClick={togglePasswordVisibility}
                                    className="toggle-password-btn"
                                >
                                    {showPassword ? <FaRegEyeSlash style={{ fontSize: "1.6em" }} /> : <FaRegEye style={{ fontSize: "1.6em" }} />}
                                </button>
                            </div>
                            <a href="">Esqueceu a senha ?</a>
                            <div className="button-container">
                        <button onClick={showInfo()} className='submit-button' type="submit">Login</button>
                            </div>
                        </div>
                        <button className='google-button'>Google</button>
                        <a id='criar-button' href="">Criar conta</a>
                    </form>
                </div>
            </div>
        </div>
    )
};

export default LoginScreen;
