import React, { useState, useEffect, useRef } from "react";
import "../styles/BaseScreen.css";
import NavFiltro from "../components/NavFiltro/NavFiltro";
import Footer from "../components/Footer/Footer";
import Reels from "../components/Reels/Reels";
import api from "../api";

const CATEGORIAS = ["diversao", "comer_beber", "relaxar"];
const FILTROS = ["individual", "casal", "familia"];

const TITULOS_CATEGORIAS = {
    diversao: "Diversão",
    comer_beber: "Comer e Beber",
    relaxar: "Relaxar"
};

const BaseScreen = () => {
    const [title, setTitle] = useState(TITULOS_CATEGORIAS["diversao"]);
    const [categoria, setCategoria] = useState("diversao");
    const [filtro, setFiltro] = useState("individual");
    const [experiences, setExperiences] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const reelsBoxRef = useRef(null);

    // Função simples para scroll lento
    const smoothScroll = (delay = 1000) => {
        if (reelsBoxRef.current) {
            setTimeout(() => {
                reelsBoxRef.current.scrollTo({ top: reelsBoxRef.current.scrollHeight, behavior: 'smooth' });
            }, delay);
        }
    };

    // Carregar experiências ao mudar categoria ou filtro
    useEffect(() => {
        getExperiencias();
    }, [categoria, filtro]);

    const getExperiencias = async () => {
        try {
            const res = await api.get_Exp_CatFilter(categoria, filtro);
            if (res.data.length === 0) {
                console.warn(`Sem mídias para ${categoria}/${filtro}, avançando...`);
                avancarCategoriaOuFiltro();
            } else {
                setExperiences(res.data);
                setCurrentIndex(0); // 🔹 Sempre resetar para o primeiro da lista
                if (reelsBoxRef.current) reelsBoxRef.current.scrollTo(0, 0);
            }
        } catch (err) {
            console.error("Erro ao carregar experiências:", err);
            alert("Erro ao carregar vídeos.");
        }
    };

    const changeFiltro = (value) => {
        setFiltro(value);
    };

    const changeCategoryAndTitle = (newCategory) => {
        setCategoria(newCategory);
        setTitle(TITULOS_CATEGORIAS[newCategory]);
    };

    // Avançar para o próximo Reels
    const handleNextReel = () => {
        if (currentIndex < experiences.length - 1) {
            setTimeout(() => {
                setCurrentIndex(prev => prev + 1);
                smoothScroll(500); // Scroll mais lento entre reels
            }, 500);
        } else {
            avancarCategoriaOuFiltro();
        }
    };

    // Troca de categoria ou filtro ao final da lista
    const avancarCategoriaOuFiltro = () => {
        const catIndex = CATEGORIAS.indexOf(categoria);
        const filtroIndex = FILTROS.indexOf(filtro);

        setTimeout(() => {
            if (catIndex < CATEGORIAS.length - 1) {
                changeCategoryAndTitle(CATEGORIAS[catIndex + 1]);
            } else {
                if (filtroIndex < FILTROS.length - 1) {
                    setFiltro(FILTROS[filtroIndex + 1]);
                    changeCategoryAndTitle(CATEGORIAS[0]);
                } else {
                    setFiltro(FILTROS[0]);
                    changeCategoryAndTitle(CATEGORIAS[0]);
                }
            }
            smoothScroll(1500); // Scroll mais lento entre categorias
        }, 1500);
    };

    return (
        <div className="screen-body">
            <div className="screen-container">
                <header className="screen-header">
                    <h2>{title}</h2>
                    <NavFiltro setFiltro={changeFiltro} filtro={filtro} />
                </header>
                <main className="screen-content">
                    <div className="reels-box" ref={reelsBoxRef}>
                        {experiences.length > 0 ? (
                            <Reels
                                key={currentIndex}
                                n={currentIndex}
                                ex={experiences[currentIndex]}
                                onVideoEnd={handleNextReel}
                            />
                        ) : (
                            <p>Carregando...</p>
                        )}
                    </div>
                </main>
                <Footer setCatTitle={changeCategoryAndTitle} categoria={categoria} />
            </div>
        </div>
    );
};

export default BaseScreen;
