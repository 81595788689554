import axios from 'axios';

const api = {
  baseUrl: "https://menuferias.com:8084",

  /* GET */
  get_Exp_All: () => {
    return axios.get(`${api.baseUrl}/experiences/`);
  },

  get_Exp_Cat: (categoria) => {
    return axios.get(`${api.baseUrl}/experiences/`, { params: { categoria } });
  },

  get_Exp_CatFilter: (categoria, filtro) => {
    return axios.get(`${api.baseUrl}/experiences/`, { params: { categoria, filtro } });
  },

  get_Detail: (id) => {
    return axios.get(`${api.baseUrl}/experiences/${id}/`);
  },

  get_Midia: (categoria, id) => {
    return axios.get(`${api.baseUrl}/midia/`, { params: { categoria, id } });
  },

  /* POST */
  post_Exp: (data) => {
    return axios.post(`${api.baseUrl}/experiences/create/`, data);
  },

  /* DELETE */
  delete_Exp: (id) => {
    return axios.delete(`${api.baseUrl}/experiences/${id}/delete/`);
  },
};

export default api;


// import axios from 'axios';

// const api = {
//   baseUrl: "https://menuferias.com:8084",

//   /* GET */

//   get_Exp_All: () => {
//     return axios.get(`${api.baseUrl}/experiences/`);
//   },

//   get_Exp_Cat: (categoria) => {
//     return axios.get(`${api.baseUrl}/experiences/${categoria}/`);
//   },

//   get_Exp_CatFilter: (categoria, filtro) => {
//     return axios.get(`${api.baseUrl}/experiences/${categoria}/${filtro}`);
//   },

//   get_Detail: (categoria, id) => {
//     return axios.get(`${api.baseUrl}/experiences/${categoria}/${id}/`);
//   },

//   get_Midia: (categoria, id) => {
//     return axios.get(`${api.baseUrl}/midia/${categoria}/${id}`);
//   },

//   /* POST */

//   post_Exp: (data) => {
//     return axios.post(`${api.baseUrl}experiences/create/`, data);
//   },

//   /* DELETE */

//   delete_Exp: (categoria, id) => {
//     return axios.delete(`${api.baseUrl}experiences/${categoria}/${id}/delete/`);
//   },

// };

// export default api;