import { React, useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import "../styles/detailscreen.css"
import { FaMapMarkerAlt, FaClock, FaCheck, FaRegStar, FaWhatsapp } from "react-icons/fa";

import api from "../api";

const DetailScreen = () => {

    const [experience, setExperience] = useState([])
    const { categoria, id } = useParams();

    useEffect(() => {
        getExperiencia();
    }, []);

    const getExperiencia = () => {
        api.get_Detail(categoria, id)
            .then((res) => res.data)
            .then((data) => setExperience(data))
            .catch((err) => alert(err));
    };

    const goBack = () => {
        window.history.back(); // Navega para a página anterior no histórico
    };


    return (
        <div className="screen-body-d">
            <div className="screen-container-d">
                <div className="nav-top">
                    <button onClick={goBack} className="back-button">Voltar</button>
                    <h2>{categoria}</h2>
                </div>
                <main className="screen-content-d">
                    <div className="details-container">
                        <img src="./fotos/buggy.png" alt="" className="detail-img" />
                        <div className="text-container">
                            <div className="title-container">
                                <h2>{experience.nome}</h2>
                                <h4>R$ {experience.valormax}</h4>
                            </div>
                            <p>{experience.descricao}</p>
                        </div>
                        <div className="info-container">
                            <div className="info">
                                <div className="icons-info"> <FaMapMarkerAlt style={{ color: "#EE790E", fontSize: "20px" }} /> <h3>{`${experience.localizacao}`}</h3> </div>
                                <div className="icons-info"> <FaClock style={{ color: "#EE790E", fontSize: "20px" }} /> <h3>{`${experience.duracao} de duração`}</h3> </div>
                                <div className="icons-info"> <FaCheck style={{ color: "#EE790E", fontSize: "20px" }} /> <h3>Instrutores Certificados</h3> </div>
                                <div className="icons-info"> <FaCheck style={{ color: "#EE790E", fontSize: "20px" }} /> <h3>Voar com o Instrutor</h3> </div>
                            </div>
                        </div>
                        <div className="star-rating">
                            <FaRegStar style={{ color: "#EE790E", fontSize: "25px" }} />
                            <FaRegStar style={{ color: "#EE790E", fontSize: "25px" }} />
                            <FaRegStar style={{ color: "#EE790E", fontSize: "25px" }} />
                            <FaRegStar style={{ color: "#EE790E", fontSize: "25px" }} />
                            <FaRegStar style={{ color: "#EE790E", fontSize: "25px" }} />
                        </div>
                        <button className="contato-whats"> <FaWhatsapp style={{ color: "#ffffff", fontSize: "25px" }} />Contato</button>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default DetailScreen