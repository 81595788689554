import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./reels.css";
import { FaHeart, FaShare, FaMapMarkerAlt, FaClock, FaStar } from "react-icons/fa";
import api from "../../api";

export default function Reels({ n, ex, onVideoEnd, reset }) {
  const mediaRef = useRef(null);
  const [mediaPath, setMediaPath] = useState("");
  const [mediaType, setMediaType] = useState("video");
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(10);
  const [isPlaying, setIsPlaying] = useState(false);
  const intervalRef = useRef(null);

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const res = await api.get_Midia(ex.categoria, ex.id);
        const video = res.data.find((m) => m.tipo_midia === "video");
        const image = res.data.find((m) => m.tipo_midia === "imagem");

        if (video) {
          setMediaPath(`https://menuferias.com:8084${video.path}`);
          setMediaType("video");
        } else if (image) {
          setMediaPath(`https://menuferias.com:8084${image.path}`);
          setMediaType("imagem");
        } else {
          setMediaPath("/videos/menu_default_video.mp4");
          setMediaType("video");
        }
      } catch (err) {
        alert("Erro ao carregar mídia");
      }
    };
    fetchMedia();
  }, [ex.categoria, ex.id, reset]);

  useEffect(() => {
    if (mediaType === "video") {
      if (mediaRef.current) {
        mediaRef.current.pause(); // 🔹 Garante que o vídeo anterior não atrapalhe
        mediaRef.current.load();
        setTimeout(() => {
          mediaRef.current?.play().catch((err) => console.warn("Erro ao dar play no vídeo:", err));
        }, 300);
        setIsPlaying(true);
      }
    } else {
      iniciarProgressoImagem();
    }
    return () => clearInterval(intervalRef.current);
  }, [mediaPath]);

  const iniciarProgressoImagem = () => {
    setProgress(0);
    let time = 0;
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      time += 100;
      setProgress((time / 10000) * 100);
      if (time >= 10000) {
        clearInterval(intervalRef.current);
        setIsPlaying(false);
        onVideoEnd();
      }
    }, 100);
  };

  const handleLoadedMetadata = () => {
    if (mediaRef.current) {
      setDuration(mediaRef.current.duration);
    }
  };

  useEffect(() => {
    if (mediaType === "video") {
      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(() => {
        if (mediaRef.current) {
          setProgress((mediaRef.current.currentTime / duration) * 100);
        }
      }, 100);
      return () => clearInterval(intervalRef.current);
    }
  }, [mediaType, duration]);

  const handleClick = () => {
    if (mediaRef.current) {
      if (mediaRef.current.paused) {
        mediaRef.current.play();
        setIsPlaying(true);
      } else {
        mediaRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  // Formatar o tempo em minutos:segundos
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <div className="reel-container" onClick={handleClick}>
      {mediaType === "video" ? (
        <video
          className="reel-video"
          ref={mediaRef}
          onEnded={() => {
            setIsPlaying(false);
            onVideoEnd();
          }}
          onLoadedMetadata={handleLoadedMetadata}
          autoPlay
          playsInline
        >
          <source src={mediaPath} type="video/mp4" />
        </video>
      ) : (
        <img className="reel-image" src={mediaPath} alt="Experiência" />
      )}

      <div
        className="progress-bar"
        style={{
          width: `${progress}%`,
          background: "linear-gradient(90deg, #ff8c00, #ff4500)",
          height: "6px",
          borderRadius: "4px",
          position: "absolute",
          top: "10px",
          left: "2px",
          right: "5px",
          transition: "width 0.1s ease-in-out",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
        }}
      ></div>

      {/* Exibir o cronômetro apenas para vídeos */}
      {mediaType === "video" && (
        <div
          className="time-display"
          style={{
            position: "absolute",
            top: "20px",
            left: "270px",
            right: "10px",
            textAlign: "center",
            fontSize: "0.8em",
            color: "white",
          }}
        >
          <span>{formatTime(mediaRef.current ? mediaRef.current.currentTime : 0)} / </span>
          <span>{formatTime(duration)}</span>
        </div>
      )}

      <div className="details-container">
        <div className="actions-container">
          <div className="action">
            <FaHeart style={{ fontSize: "1.8em", color: "white" }} />
            <p>327K</p>
          </div>
          <div className="action">
            <FaShare style={{ fontSize: "1.8em", color: "white" }} />
            <p>Share</p>
          </div>
        </div>
        <div className="text-container">
          <h4>{ex.nome}</h4>
          <p>{ex.descricao}</p>
        </div>
        <div className="info-container">
          <div className="info">
            {ex.duracao && (
              <div>
                <FaClock style={{ fontSize: "1em", color: "orange" }} />
                <p>{ex.duracao}</p>
              </div>
            )}
            <div>
              <FaMapMarkerAlt style={{ fontSize: "1em", color: "orange" }} />
              <p>{ex.localizacao}</p>
            </div>
          </div>
          <div className="info preco-rating">
            {ex.valormax && (
              <div>
                <p>R$ {ex.valormax}</p>
              </div>
            )}
            <div>
              <FaStar style={{ fontSize: "1em", color: "orange" }} />
              <p>{ex.avaliacao}</p>
            </div>
          </div>
        </div>
        <button className="reel-button">
          <Link to={`/detail/${ex.categoria}/${ex.id}`}>Detalhes</Link>
        </button>
      </div>
    </div>
  );
}
