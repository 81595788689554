import React from 'react';
import '../styles/MainScreen.css';
//import SearchBar from '../components/SearchBar/SearchBar';
import Footer from '../components/Footer/Footer';

const Favorite = () => {
    return (
        <div className="main-screen-container">
          
          <div className="search-bar-container">
            <h2 className="title">Favoritos</h2>
             {/*<SearchBar />*/}
          </div>

          <div className='card-list'>

          </div>

          <Footer/>

        </div>
    );
};

export default Favorite;